export default {
  changeasset:
    "Das Asset wurde inzwischen von einem anderen Benutzer aktualisiert und Ihre Änderungen können nicht gespeichert werden. Bitte versuchen Sie es erneut.",
  general: "Allgemein",
  header: "Achtung",
  noDataAvailable: "Keine Daten verfügbar...",
  noSavetyNotes: "Es wurden noch keine Produktsicherheitsmitteilungen erstellt",
  noAssets: "Es gibt keine neuen Anlagen mehr für diese Organisation",
  errorPage: "Ups, ein Fehler ist aufgetreten!",
  invalidLatitude: "Ungültige Längengrad",
  invalidLongitude: "Ungültiger Breitengrad",
};
